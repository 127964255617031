"use client";

import { XIcon } from "lucide-react";
import { useLocalStorage } from "usehooks-ts";
import { useStore } from "zustand";

import { ClientOnly } from "~/components/client-only";
import { Translation } from "~/components/translations/component";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { Button } from "~/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { baseTaskSettings } from "~/lib/baseTaskSettings";

import { ClozeTaskDragAndDropView } from "./drag-and-drop";
import { type ClozeViewType, type Props, useClozeTaskStore } from "./provider";
import { ClozeTaskSpellView } from "./spell";

interface PreviewProps {
  taskInstance: Props["taskInstance"];
}

export const Preview = ({ taskInstance }: PreviewProps) => {
  const Store = useClozeTaskStore();
  const { view, setView } = useStore(Store, (s) => ({
    view: s.view,
    setView: s.setView,
  }));
  const fakeSettings = {
    showAnswersToStudents: false,
    studentView: "SPELL",
    type: "CLOZE",
  } as const;
  return (
    <div className="flex flex-col gap-2">
      <ClientOnly>
        <TeacherAlert />
      </ClientOnly>
      <Tabs
        value={view}
        onValueChange={(view) => setView(view as ClozeViewType)}
        className="w-full"
      >
        <TabsList className="mx-auto grid w-full max-w-[400px] grid-cols-2">
          <TabsTrigger value="DRAGANDDROP">
            <Translation id="task.cloze.student-view.drag-and-drop">
              Dra-och-släpp
            </Translation>
          </TabsTrigger>
          <TabsTrigger value="SPELL">
            <Translation id="task.cloze.student-view.spell">
              Skriva orden
            </Translation>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="DRAGANDDROP">
          <ClozeTaskDragAndDropView
            groupId={taskInstance.groupId}
            taskInstanceId={taskInstance.id}
            instanceSettings={fakeSettings}
          />
        </TabsContent>
        <TabsContent value="SPELL">
          <ClozeTaskSpellView
            groupId={taskInstance.groupId}
            taskInstanceId={taskInstance.id}
            instanceSettings={fakeSettings}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

function TeacherAlert() {
  const [show, setShow] = useLocalStorage(
    "@nextgen/teacher/cloze/alert",
    true,
    {
      initializeWithValue: false,
    },
  );
  const Icon = baseTaskSettings.CLOZE.Icon;

  if (!show) {
    return null;
  }
  return (
    <Alert className="mx-auto max-w-lg">
      <Icon className="h-4 w-4" />
      <AlertTitle className="flex items-start justify-between">
        <p className="mt-1">
          <Translation id="task.cloze.preview.title">
            Förhandsgranska uppgiften
          </Translation>
        </p>
        <Button size="icon-sm" variant="ghost" onClick={() => setShow(false)}>
          <XIcon className="h-4 w-4" />
        </Button>
      </AlertTitle>
      <AlertDescription>
        <Translation id="task.cloze.preview.description">
          Vid utdelning av uppgiften väljer du i vilket läge eleven ska arbeta.
          Eleven kan inte välja visningsläge själv. Nedanför kan du
          förhandsgranska båda lägena.
        </Translation>
      </AlertDescription>
    </Alert>
  );
}
