"use client";

import { AlertCircle } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

import { DashboardHeader } from "~/components/header";
import { LoadingButton } from "~/components/loading-button";
import { Textarea } from "~/components/textarea-autosize";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Alert, AlertTitle } from "~/components/ui/alert";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import {
  type DictationTaskInsertSchema,
  type DictationTaskSelectSchema,
} from "~/server/zod/tasks";

import { PostSubmit, type TaskProps } from "./shared";

type Props =
  | TaskProps<DictationTaskSelectSchema>
  | TaskProps<DictationTaskInsertSchema>;

export const TaskDictation = ({ task, taskInstance, preview }: Props) => {
  const [done, setDone] = useState(false);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (preview) {
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: task.type,
      text: answer,
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      setLoading(false);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId: taskInstance.id,
      groupId: taskInstance.groupId,
    });
    setLoading(false);
    setDone(true);
  }

  const placeholderText = useTranslation(
    "task.dictation.placeholder",
    "Diktera texten här",
  );

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {!preview && (
          <DashboardHeader heading={task.name}>
            <LoadingButton
              isLoading={loading}
              disabled={done || loading}
              type="submit"
            >
              <Translation id="common.turn-in">Lämna in</Translation>
            </LoadingButton>
          </DashboardHeader>
        )}
        {done ? (
          <PostSubmit
            groupId={taskInstance.groupId}
            preview={preview}
            taskType="DICTATION"
          />
        ) : (
          <div className="grid md:grid-cols-5">
            <div className="md:col-span-3">
              <Textarea
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                disabled={loading}
                minRows={10}
                placeholder={placeholderText}
              />
            </div>
            <div className="md:col-span-2">
              {task.audioClipId && task.studentCanPlayAudio ? (
                <div className="flex w-full flex-col gap-2 p-2">
                  <audio controls className="w-full">
                    <source
                      src={`/static/audio-clip/${task.audioClipId}`}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  <p className="text-muted-foreground text-sm">
                    <Translation id="task.dictation.listen-to-audio">
                      Lyssna på ljudfilen ovan och diktera texten i rutan
                      bredvid.
                    </Translation>
                  </p>
                </div>
              ) : (
                <Alert className="relative mx-auto my-4 flex max-w-2xl flex-row justify-center">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>
                    <Translation id="task.dictation.no-audio">
                      Din lärare kommer att spela upp en ljudfil för dig att
                      diktera
                    </Translation>
                  </AlertTitle>
                </Alert>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
