"use client";

import FuzzySet from "fuzzyset";
import { useEffect } from "react";
import { useWizard } from "react-use-wizard";
import { z } from "zod";
import { useStore } from "zustand";

import { Confetti } from "~/components/confetti";
import { Translation } from "~/components/translations/component";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Progress } from "~/components/ui/progress";

import { NEXT_STEP_DELAY } from "../task-word2/config";
import { useWordTaskStore } from "./provider";
import { type Word } from "./step";

export function SpellStep({ word }: { word: Word }) {
  const Store = useWordTaskStore();
  const setAnswer = useStore(Store, (s) => s.setAnswer);
  const answers = useStore(Store, (s) => s.answers);
  const task = useStore(Store, (s) => s.computed.task);
  const ignoreWhitespace = useStore(Store, (s) => s.ignoreWhiteSpace);

  const answer = answers[word.id] ?? {
    id: word.id,
    data: {
      value: "",
      percentage: 0,
      done: false,
    },
  };

  const { nextStep } = useWizard();
  useEffect(() => {
    if (answer.data.done) {
      setTimeout(() => {
        void nextStep();
      }, NEXT_STEP_DELAY);
    }
  }, [answer]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let right = word.right
      .trim()
      .split("\n")
      .map((w) => w.trim())
      .filter(Boolean);
    let left = e.target.value.trim();
    if (ignoreWhitespace) {
      left = left.replace(/\s/g, "");
      right = right.map((w) => w.replace(/\s/g, ""));
    }
    const fuzzy = FuzzySet(right).get(left, undefined, 0);
    const percentage = fuzzy?.[0]?.[0] ?? 0;
    const done = percentage === 1;
    setAnswer({
      id: word.id,
      data: {
        ...answer.data,
        value: e.target.value,
        percentage,
        done,
      },
    });
  }

  const percentage = answer.data.percentage ?? 0;

  function handleShowAnswer() {
    setAnswer({
      id: word.id,
      data: {
        ...answer.data,
        wasShown: true,
      },
    });
  }

  return (
    <div className="container max-w-[400px]">
      <Input
        autoFocus
        value={answer.data.value?.toString() ?? ""}
        onChange={handleChange}
        disabled={answer.data.done as boolean}
        className="my-4"
      />
      {answer.data.wasShown && (
        <div className="text-center">
          <Badge variant="secondary">{task.rightTitle}</Badge>
          <h2 className="py-2 text-2xl font-bold">{word.right}</h2>
        </div>
      )}
      {answer.data.wasShown ? (
        <Button
          className="mx-auto"
          variant="outline"
          onClick={() => nextStep()}
        >
          <Translation id="app.task-word2.continue">Gå vidare</Translation>
        </Button>
      ) : (
        <div className="flex flex-row justify-between">
          <Button
            className="mx-auto"
            variant="outline"
            onClick={handleShowAnswer}
            disabled={answer.data.done as boolean}
          >
            <Translation id="app.task-word2.show-answer">
              Visa svaret
            </Translation>
          </Button>

          <Button className="mx-auto" variant="ghost">
            {answer.data.done ? <Confetti /> : `${floatToPercent(percentage)}%`}
          </Button>
        </div>
      )}
      <Progress
        className="text-secondary mt-2 w-full"
        value={Number(answer.data.percentage ?? 0) * 100}
      ></Progress>
    </div>
  );
}

function floatToPercent(f: unknown) {
  const p = z.coerce.number().min(0).max(1).parse(f);
  return Math.round(p * 100);
}
