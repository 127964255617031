import { type OcclusionResult } from "~/lib/baseTaskSettings";
import type { ClozeRoot, Occlusion } from "~/server/zod/cloze";

interface RenderClozeLexicalProps {
  clozeRoot: ClozeRoot;
  occlusions: OcclusionResult[];
  onOcclusion: (props: {
    occlusionNode: Occlusion;
    occlusion: OcclusionResult;
    index: number;
  }) => React.ReactNode | null;
}

export function RenderClozeLexical({
  clozeRoot,
  occlusions,
  onOcclusion,
}: RenderClozeLexicalProps) {
  // I don't really like this, but it's the easiest way to keep track of
  // which occlusions have been used this render cycle.
  const usedIndexes: number[] = [];

  return (
    <div className="bg-background rounded-xl p-4 shadow-lg transition-[height] duration-300 ease-in-out">
      {clozeRoot.root.children.map((paragraph, i) => {
        return (
          <div key={i} className="whitespace-break-spaces py-1">
            {paragraph.children.map((child, j) => {
              if (child.type === "occluded") {
                const occlusion = occlusions.find(
                  (occlusion) =>
                    occlusion.label === child.text &&
                    !usedIndexes.includes(occlusion.index),
                );
                if (!occlusion) {
                  return child.text;
                }
                usedIndexes.push(occlusion.index);
                return onOcclusion({
                  occlusionNode: child,
                  occlusion,
                  index: j,
                });
              }
              if (child.type === "text") {
                return child.text;
              }
              return null;
            })}
          </div>
        );
      })}
    </div>
  );
}
