import { confetti } from "@tsparticles/confetti";
import { useEffect } from "react";

import { logger } from "~/lib/logger";

interface ConfettiProps {
  onlyEffect?: boolean;
}

export function Confetti({ onlyEffect }: ConfettiProps) {
  useEffect(() => {
    triggerConfetti();
  }, []);

  if (onlyEffect) {
    return null;
  }

  return <>🎉</>;
}

export function triggerConfetti() {
  const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
  logger.debug(mediaQuery);
  if (!mediaQuery || mediaQuery.matches) {
    // user has disabled animations, do nothing
  } else {
    void confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }
}
