"use client";

import { cn } from "~/lib/utils";

interface CompletedTargetProps {
  text: string;
  ok: boolean;
}

export function CompletedTarget({ text, ok }: CompletedTargetProps) {
  return (
    <span
      className={cn("rounded border p-1", {
        "border-green-500 bg-green-200 dark:border-green-500 dark:bg-green-800":
          ok,
        "border-red-500 bg-red-200 dark:border-red-500 dark:bg-red-800": !ok,
      })}
    >
      {text}
    </span>
  );
}
