import { type User } from "lucia";
import { ChevronRight } from "lucide-react";

import { Link } from "~/components/link";
import { useTaskSettingsStore } from "~/components/task-settings-provider";
import { Translation } from "~/components/translations/component";
import { Button } from "~/components/ui/button";
import { type TaskTypeEnum } from "~/lib/baseTaskSettings";
import { cn } from "~/lib/utils";
import { type getCompleteTaskInstance } from "~/server/data/tasks";
import { type TaskSharedShema } from "~/server/zod/tasks";

interface Props {
  groupId: string | number;
  preview?: boolean;
  isSeb?: boolean;
  taskType: TaskTypeEnum;
  onReset?: () => void;
}

export const PostSubmit = ({
  groupId,
  preview,
  isSeb,
  taskType,
  onReset,
}: Props) => {
  const taskSettings = useTaskSettingsStore();
  const taskSetting = taskSettings[taskType];
  if (isSeb) {
    return (
      <a href={`https://example.com/seb/quit`}>
        <Button variant="default">
          <Translation id="common.finish">Avsluta</Translation> SEB
        </Button>
      </a>
    );
  }
  return (
    <div data-testid="post-submit">
      {!taskSetting.singleUse && (
        <Button
          disabled={preview}
          onClick={() => {
            if (onReset) {
              onReset();
            } else {
              window.location.reload();
            }
          }}
          variant="secondary"
        >
          <Translation id="common.start-over">Börja om</Translation>
        </Button>
      )}
      <Link
        href={`/elev/${groupId}`}
        className={cn({
          "pointer-events-none": preview,
        })}
      >
        <Button variant="default" disabled={preview}>
          <Translation id="common.back-to-group">
            Tillbaka till gruppen
          </Translation>{" "}
          <ChevronRight />
        </Button>
      </Link>
    </div>
  );
};

export type TaskProps<T> = {
  task: T & TaskSharedShema;
  taskInstance: Awaited<
    ReturnType<typeof getCompleteTaskInstance>
  >["taskInstance"];
  preview?: boolean;
  user: User;
  groupId: number;
};
